import { Color, PerspectiveCamera, OrthographicCamera, Scene, WebGLRenderer, Vector3 } from "three"
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
// GLSL
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader'
//
import Model01 from "./Model01"
// import Pattern01 from "../sample01/Pattern01"

// const assertionを使って、リテラル型として扱う
const VisType = {
  style1: 'style1',
  style2: 'style2',
  style3: 'style3',
} as const;
export type VIS_TYPE = typeof VisType[keyof typeof VisType];

//
export type PROPS_TYPE = {
  type: VIS_TYPE
  light: number
  rad: {
    x: number,
    y: number,
    z: number,
  }
}

//
export default class VisThree {
  public get canvas() {
    if (this._renderer) {
      return this._renderer.domElement
    }
    return undefined
  }
  // ---
  //
  private _scene: Scene
  private _renderer: WebGLRenderer
  private _camera: PerspectiveCamera | OrthographicCamera
  // ポスプロ用
  private _composer: EffectComposer
  // アンチエイリアス
  private _fxaaPass: ShaderPass

  // ---
  private _pattern: Model01

  //
  private _lightPos: Vector3[] = []

  // ---
  // コンストラクタ
  constructor(dom: HTMLDivElement) {
    // 表示領域のサイズ
    const rect = dom.getBoundingClientRect()

    //
    const raito = window.devicePixelRatio

    //
    this._scene = new Scene()
    this._scene.background = new Color(0x99aabb)

    // -----
    // レンダー
    this._renderer = new WebGLRenderer()
    this._renderer.setPixelRatio(raito)
    this._renderer.setSize(rect.width, rect.height)
    
    // -----
    // カメラ
    // パースペクティブカメラ
    // this._camera = new PerspectiveCamera(70, rect.width / rect.height, 1, 2000)
    // 平行カメラ
    this._camera = new OrthographicCamera(rect.width * -0.5, rect.width * 0.5, rect.height * 0.5, rect.height * -0.5)
    this._camera.zoom = 4
    // 共通
    this._camera.position.set(0, 0, 200)
    this._camera.lookAt(0, 0, 0)
    //
    this._camera.updateProjectionMatrix()

    // -----
    // ポスプロ用
    this._composer = new EffectComposer(this._renderer)
    // カメラとレンダー
    const renderPass = new RenderPass(this._scene, this._camera)
    this._composer.addPass(renderPass)
    // アンチエイリアス用
    this._fxaaPass = new ShaderPass(FXAAShader)
    this._composer.addPass(this._fxaaPass)

    // --- --- ---
    // 設定
    this._fxaaPass.material.uniforms.resolution.value.x = 1 / (rect.width * raito)
    this._fxaaPass.material.uniforms.resolution.value.y = 1 / (rect.height * raito)

    // ---
    this._composer.setPixelRatio(raito)
    this._composer.setSize(rect.width, rect.height)

    // ---
    // 演出パターン
    this._pattern = new Model01()
    /*
    const pattern = Math.random()
    if (pattern < 0.5) {
      this._pattern = new Pattern02()
    } else {
      this._pattern = new Pattern01()
    }
    */

    // ---
    this._scene.add(this._pattern.container)
    // 演出で使うライトの追加
    for (let f = 0; f < this._pattern.lights.length; f++) {
      // this._scene.add(this._pattern.lights[f])
      // カメラとの位置関係を保持したいので、シーンではなく、カメラにライトを追加（付ける）
      this._camera.add(this._pattern.lights[f])
    }
    // ライトを入れたカメラをシーンに追加
    this._scene.add(this._camera)

    // オービットコントロールの追加
    // カメラをある視点に対して、軌道上に移動させるコントローラー
    const controls = new OrbitControls(this._camera, this._renderer.domElement)
    // controls.enableZoom = false
    controls.enablePan = false

    // ---
    // domにマウント
    if (dom && !dom.hasChildNodes()) {
      dom.appendChild(this._renderer.domElement)
    }
  }

  // ---
  //
  init(props: PROPS_TYPE) {
    this._pattern.init(this._camera, props)
  }

  // ---
  //
  render(duration: number) {
    // ---
    //
    this._pattern.update(duration)

    // ---
    this._composer.render()
  }
}
