import { useEffect, useState } from 'react'
import './RecordButton.css'
//
type PROPS_TYPE = {
  onClick: VoidFunction
  active: boolean
  percent?: number
}

//
const RecordButton:React.VFC<PROPS_TYPE> = ({ onClick, active, percent }) => {
  //
  const [circleStyle, setCircleStyle ] = useState({
    strokeDasharray: '',
    strokeDashoffset: ''
  })
  //
  useEffect(() => {
    //
    if (percent) {
      var circumference = 14 * 2 * Math.PI;
      //
      const offset = circumference - percent * circumference;
      setCircleStyle({
        strokeDasharray: `${circumference} ${circumference}`,
        strokeDashoffset: offset.toString()
      })
    }
  }, [percent])
  //
  return <div className='RecordButton'>
        <svg className="RecordButton-ring" width="30" height="30">
        <circle
          className="RecordButton-ring__circle"
          stroke="white"
          strokeWidth="3"
          fill="transparent"
          r="13"
          cx="15"
          cy="15"
          style={circleStyle}
        />
      </svg>
      <button onClick={onClick} className={`RecordButton_btn${ active ? ' _active' : ''}`}></button>
    </div>
}

export default RecordButton
