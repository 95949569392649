import { useEffect, useRef, useState } from 'react'
// import VisThree from '../lib/sample01/VisThree'
import VisThree, { VIS_TYPE } from '../lib/sample02/VisThree'
import './Home.css'
import { useRenderHook } from './hooks/RenderHook'
import { captureStart } from './home/Capture'
import { useSearchParams } from 'react-router-dom'

//
// import RecordIcon from './home/RecordIcon'
import RecordButton from './home/RecordButton'

//
const Home:React.VFC = () => {
  //
  const wrapperRef = useRef<HTMLDivElement>(null)
  //
  const [ isCapture, setIsCapture ] = useState(false)
  //
  const [ recordStartTime, setRecordStartTime ] = useState(0)
  const [ recordPercent, setRecordPercent ] = useState(0)
  //
  const [ searchParams ] = useSearchParams()
  // ---
  // 描画に変更を与えない、再設定されたくない値はrefに（多用しないほうが良いらしいが）
  const startTime = useRef<number>(Date.now())
  const three = useRef<VisThree>()
  const recorder = useRef<MediaRecorder>()

  // ---
  // three.jsの初期化
  useEffect(() => {
    //
    if (wrapperRef.current) {
      if (!three.current) {
        //
        const p = (searchParams.get('effect') || '0,1,1,1').split(',')
        //
        const props = {
          type: searchParams.get('type') as VIS_TYPE,
          light: Number(p[0]),
          rad: {
            x: Number(p[1]),
            y: Number(p[2]),
            z: Number(p[3]),
          }
        }
        //
        three.current = new VisThree(wrapperRef.current)
        three.current.init(props)
      }
    }
  })

  // ---
  // requestAnimationFrame
  useRenderHook(() => {
    if (three.current) {
      const t = Date.now()
      three.current.render(t - startTime.current)
      //
      if (isCapture) {
        setRecordPercent((Date.now() - recordStartTime) / 10000)
      }
    }
  })

  // ---
  const handleCapture = () => {
    if (isCapture) {
      // キャプチャ停止
      /*
      setIsCapture(false)
      if (recorder.current) {
        recorder.current.stop()
      }
      */
    } else {
      //
      if (three.current && three.current.canvas) {
        // キャプチャ開始
        setIsCapture(true)
        recorder.current = captureStart(three.current.canvas)
        setRecordStartTime(Date.now())
        // 10秒
        window.setTimeout(() => {
          if (recorder.current) {
            recorder.current.stop()
          }
          setIsCapture(false)
        }, 10 * 1000)
      }
    }
  }

  // ---
  return (
    <div id="home">
      <RecordButton onClick={handleCapture} active={isCapture} percent={recordPercent} />
      <div className='wrapper' ref={wrapperRef}></div>
    </div>
  )
}

export default Home