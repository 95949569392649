import { DirectionalLight, DirectionalLightHelper, Group, Color } from "three"
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { PROPS_TYPE } from "./VisThree"

//
export default class Model01 {
  // ---
  get lights() {
    return this._lights
  }
  get container() {
    return this._container
  }
  // ---
  //
  private _lights: (DirectionalLight | DirectionalLightHelper)[] = []
  //
  private _container: Group

  // パターン
  private _patternNum = 0
  private _patterns = [
    {
      colors: [ 0xbb5599, 0xeeddcc]
    },
    {
      colors: [ 0xffcc33, 0x66cc00]
    },
    {
      colors: [ 0x00ccff, 0xff33cc]
    },
  ]

  // ---
  //
  constructor() {
    this._patternNum = Math.floor(Math.random() * this._patterns.length)
    const pt = this._patterns[this._patternNum]
    // ---
    //
    this._container = new Group()
    // -----
    //
    const light1 = new DirectionalLight(pt.colors[0], 0.6)
    light1.position.x = -400
    light1.position.z = 800
    // this._lights.push(new DirectionalLightHelper(light1))
    this._lights.push(light1)
    // ---
    const light2 = new DirectionalLight(pt.colors[1], 0.6)
    light2.position.y = -320
    light2.position.z = 800
    // this._lights.push(new DirectionalLightHelper(light2))
    this._lights.push(light2)
  }

  //
  init (_: any, props?: PROPS_TYPE) {
    //
    let loadPath = `/models/test${Math.floor(Math.random() * 2) + 1}.glb`
    let radX = Math.random() * Math.PI
    let radY = Math.random() * Math.PI
    let radZ = Math.random() * Math.PI
    //
    if (props) {
      if (props.type === 'style1') {
        loadPath = `/models/test1.glb`
      } else if (props.type === 'style2') {
        loadPath = `/models/test2.glb`
      } else {
        loadPath = `/models/test3.glb`
      }
      //
      radX = props.rad.x * (Math.PI / 180)
      radY = props.rad.y * (Math.PI / 180)
      radZ = props.rad.z * (Math.PI / 180)
      //
      this._patternNum = Math.min(this._patterns.length - 1, Math.max(0, props.light))
      const pt = this._patterns[this._patternNum]
      for (let i = 0; i < this._lights.length; i++) {
        this._lights[i].color = new Color(pt.colors[i])
      }
    }

    // ---
    new GLTFLoader().load(loadPath, (gltf) => {
      gltf.scene.scale.x = 30
      gltf.scene.scale.y = 30
      gltf.scene.scale.z = 30
      gltf.scene.rotateX(radX)
      gltf.scene.rotateY(radY)
      gltf.scene.rotateZ(radZ)
      this._container.add(gltf.scene)
    })
  }

  //
  update(duration: number) {
    // ---
    this._container.rotateX(0.0002)
    this._container.rotateY(0.0004)
    this._container.rotateZ(0.0006)
  }
}