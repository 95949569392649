/**
 * Canvasのキャプチャを実行する
 */

let chunks: Blob[]
//
export const captureStart = (target: HTMLCanvasElement) => {
  //
  chunks = []
  //
  const types = [
    { mime: 'video/mp4', ext: 'mp4' },
    { mime: 'video/webm;codecs=vp9', ext: 'webm' },
    { mime: 'video/webm;codecs=vp8', ext: 'webm' },
  ]
  //
  let recordType = types[0]
  //
  while(types.length) {
    const mt = types.shift()
    // @ts-ignore
    if (MediaRecorder.isTypeSupported(mt.mime) && mt) {
      recordType = mt
    }
  }
  //
  const stream = target.captureStream()
  //
  const recorder = new MediaRecorder(stream, { mimeType: recordType.mime })
  // ---
  //
  recorder.onstop = (e) => {
    console.log('renderStop')
    //
    const blob = new Blob(chunks, { type: recordType.ext })
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")
    document.body.appendChild(a)
    a.download = `video.${recordType.ext}`
    a.href = url
    a.click()
    a.remove()
    //
    URL.revokeObjectURL(url)
  }
  // ---
  //
  recorder.ondataavailable = (e) => {
    chunks.push(e.data)
  }
  //
  recorder.start()
  //
  return recorder
}